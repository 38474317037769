/***************************************************************************************************
* COMPONENTS AND THEMES
*/

/*
* Angular Material, material design components
* see https://material.angular.io
*/
@import 'libs/shared/ui/styles/src/lib/scss/m3-theme';
/***************************************************************************************************
  * UTILITIES
  */

/*
  * TailwindCSS, utility-first css framework
  * see https://tailwindcss.com/docs
  */

/*
 *  Officially nesting layers like this is not suported by tailwind and results in an error upon generation of the CSS files by PostCSS
 *  As of now, inspecting the source and testing the UI, this officially unsupported syntax hasn't caused any issues (yet).
 *  The prefix is required to prevent tailwind styles leaking into the global scope of the embedding site. If this behavior would cause
 *  problems down the road, we cannot simply remove the prefix
 *
 *  @see https://github.com/tailwindlabs/tailwindcss/issues/5521
 * ========
 */
lubricator-selection-assistant {
  @tailwind base;
  @tailwind components;
}

@tailwind utilities;
/*
 * Tailwind preflight modified with application scope only
 */
@import './preflight';

lubricator-selection-assistant {
  @layer resets {
    * {
      font-family:
        Noto Sans,
        sans-serif !important;
    }

    .material-icons {
      font-family: 'Material Icons' !important;
    }
  }
  @apply bg-white;

  .mat-mdc-mini-fab .mat-mdc-button-ripple {
    @apply hidden;
  }

  //radio button overrides
  .mat-mdc-radio-button.mat-primary {
    --mat-radio-checked-ripple-color: none;
  }

  .mat-mdc-radio-button {
    @apply text-title-small;

    .mdc-radio {
      transform: scale(0.7);
      @apply pr-1;
    }

    .mdc-form-field {
      height: 28px;
      @apply pr-2;
    }

    .mdc-label {
      @apply pr-1;
      @apply cursor-pointer;
      @apply text-on-surface-variant;
    }

    // green background
    &.mat-mdc-radio-checked {
      .mdc-form-field {
        @apply rounded-full;
        @apply bg-secondary-legacy-50;
      }

      .mdc-label {
        @apply text-primary #{!important};
      }
    }

    &:not(.mat-mdc-radio-checked) {
      @apply text-on-surface-variant;
    }
  }

  .lubricantPanelOverlay .mat-mdc-select-panel {
    @apply max-w-full #{!important};
    @apply w-full #{!important};
  }

  .accessory-table {
    @apply w-full;
    th {
      @apply text-body-small;
    }
    .accessory-group {
      @apply [&>th]:py-2 [&>th]:px-2 bg-background-dark text-left rounded-t-md border-x-4 border-[#f5f5f5];

      th:first-of-type {
        @apply pl-2;
      }
    }

    .accessory-item {
      @apply bg-surface-legacy [&>td]:py-4 border-l-2 border-border last-of-type:border-b-4 last-of-type:border-[#f5f5f5];

      td:first-of-type {
        @apply border-l-4 border-l-[#f5f5f5];
      }

      td:last-of-type {
        @apply border-r-4 border-r-[#f5f5f5];
      }
    }

    .accessory-group-spacing {
      td {
        height: 25px;
        @apply border-t-4 border-[#f5f5f5];
      }
    }
  }

  .recommendation-table {
    .mat-column-field {
      @apply w-40 sm:w-24 md:w-60;
    }

    .mat-column-recommended {
      @apply w-40 sm:w-24 md:w-72 lg:min-w-[240px] xl:w-60;
    }

    .mat-column-minimum {
      @apply w-40 sm:w-24  md:w-72 lg:min-w-[240px] xl:w-60;
    }
  }
}
